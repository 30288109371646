<style lang="scss" scoped>
.line {
	margin: 0.3rem 0.3rem;
	height: 1px;
	background: $line_color;
}
</style>

<template>
	<div>
		<div class="split_line line"></div>
	</div>
</template>

<script>
export default {
	name: 'splitLine',
};
</script>
