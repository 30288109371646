<style lang="scss" scoped>
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
</style>

<template>
	<div class="subject_form">
		<active-title>标地信息</active-title>
		<van-form ref="form" input-align="right" error-message-align="right" :scroll-to-error="true">
			<van-cell-group :border="false">
				<van-field label="工程项目名称" v-if="projectName || projectName === ''" v-model="subject.projectName" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写工程项目名称" :rules="rules.projectName" />
				<van-field label="工程造价(万)" v-if="projectCost || projectCost === ''" v-model="subject.projectCost" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写工程造价" readonly :rules="rules.projectCost" />
				<van-field label="施工内容" v-if="projectContent || projectContent === ''" :value="subject.projectContent" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" :rules="rules.projectContent" placeholder="请选择施工内容" @click="projectContentPop = true" />
				<van-field label="施工地址" v-if="projectAddress || projectAddress === ''" v-model="subject.projectAddress" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="省-市-县-详细地址" :rules="rules.projectAddress" />

				<van-field label="场地面积(㎡)" v-if="area || area === ''" v-model="subject.area" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写场地面积" readonly :rules="rules.area" />
				<van-field label="营业场地人数" v-if="peopleNum || peopleNum === ''" v-model="subject.peopleNum" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写营业场地人数" readonly :rules="rules.peopleNum" />
				<van-field label="营业场所地址" v-if="businessAddress || businessAddress === ''" v-model="subject.businessAddress" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="省-市-县-详细信息" :rules="rules.businessAddress" />
			</van-cell-group>
		</van-form>

		<!-- 施工内容 -->
		<van-popup v-model="projectContentPop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="施工内容" show-toolbar value-key="name" :visible-item-count="3" :columns="projectContentList" @confirm="projectContentCheck" @cancel="projectContentPop = false" />
		</van-popup>
	</div>
</template>

<script>
import { CellGroup, Popup, Picker, Calendar, Field, Form, Toast } from 'vant';

export default {
	name: 'subjectForm', // 标的信息
	components: {
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[Form.name]: Form,
		[Toast.name]: Toast,
	},
	props: {
		// 标的信息，由于信息差异较大，所以，在props下，所有字段都默认不显示
		projectName: {
			type: [String, Boolean],
			required: false,
			ddefault: false,
		},

		projectCost: {
			type: [String, Boolean],
			required: false,
			ddefault: false,
		},

		projectContent: {
			type: [String, Boolean],
			required: false,
			ddefault: false,
		},

		projectAddress: {
			type: [String, Boolean],
			required: false,
			ddefault: false,
		},

		area: {
			type: [String, Boolean],
			required: false,
			ddefault: false,
		},

		businessAddress: {
			type: [String, Boolean],
			required: false,
			ddefault: false,
		},

		peopleNum: {
			type: [String, Boolean],
			required: false,
			ddefault: false,
		},

		// 工程造价(万)的值，该值是在详情页面选择的，不可修改
		projectPrice: {
			type: [String, Number],
			required: false,
			default: 0,
		},

		// 场地面积的值，该值是在详情页面选择的，不可修改
		areaValue: {
			type: [String, Number],
			required: false,
			default: 0,
		},

		// 营业场地人数，该值是在详情页面选择的，不可修改
		peopleNumValue: {
			type: [String, Number],
			required: false,
			default: 0,
		},
	},
	data() {
		return {
			// 标地信息
			subject: {
				projectName: '', // 工程项目名称
				projectCost: '', // 工程造价(万)
				projectContent: '', // 施工内容
				projectDict: null, // 施工内容对象
				projectAddress: '', // 施工地址
				area: '', // 场地面积
				businessAddress: '', // 营业场所地址
				peopleNum: '', // 营业场地人数
			},

			projectContentPop: false,

			rules: {
				projectName: [{ required: true }],
				projectCost: [{ required: true }],
				projectContent: [{ required: false, message: '请选择施工内容' }],
				projectAddress: [{ required: true }],
				area: [{ required: true }],
				businessAddress: [{ required: true }],
			},
		};
	},
	computed: {
		projectContentList() {
			let result = [];
			let sundryFormInput = this.$store.state.sundryFormInput;
			if (typeof sundryFormInput === 'object' && Object.hasOwnProperty.call(sundryFormInput, 'otherInfo') && sundryFormInput.otherInfo.length) {
				let constructionContentList = sundryFormInput.otherInfo.filter(item => item.fieldName === 'constructionContent');
				if (constructionContentList.length) {
					result = JSON.parse(constructionContentList[0].validateRules).valueLimit;
				}
			}
			return result;
		},
	},
	created() {
		this.subject.projectCost = this.projectPrice;
		this.subject.area = this.areaValue;
		this.subject.peopleNum = this.peopleNumValue;
	},
	methods: {
		getData() {
			return new Promise(resolve => {
				this.$refs.form
					.validate()
					.then(() => {
						let r = {};
						for (const key in this._props) {
							if (Object.hasOwnProperty.call(this._props, key)) {
								const val = this._props[key];
								if (val !== undefined) {
									r[key] = this.subject[key];
								}
								if (key === 'projectContent') {
									r.projectDict = this.subject.projectDict;
								}
							}
						}
						resolve(r);
					})
					.catch(() => {
						Toast('请检查标地信息');
					});
			});
		},

		projectContentCheck(val) {
			this.subject.projectContent = val.name;
			this.subject.projectDict = val;
			this.projectContentPop = false;
		},
	},
};
</script>
