<style lang="scss" scoped>
::v-deep .must_read_pop {
	.van-action-sheet__content {
		overflow: hidden;
	}
}
.must_read_content {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 0 0.3rem 0.3rem;
	height: 70vh;
	box-sizing: border-box;
	.tabs {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.5rem;
		.tab {
			display: flex;
			flex-direction: column;
			align-items: center;
			.text {
				font-size: 0.28rem;
				line-height: 0.36rem;
				color: $font_color_val;
			}
			.icon {
				width: 0.3rem;
				height: 0.06rem;
				margin-top: 0.16rem;
			}
		}
		.active {
			.text {
				color: $color_main;
			}
			.icon {
				background-color: $color_main;
			}
		}
	}
	.content {
		flex: 1;
		overflow-y: auto;
	}
	.loading_btn_box {
		width: 100%;
		padding: 0.2rem 0.3rem;
		box-sizing: border-box;
		background-color: #fff;
		.loading_btn {
			border-radius: 8px;
			height: 0.8rem;
		}
	}
	.content_info {
		word-break: break-all;
	}
}
.must_read_pop {
	z-index: 3001 !important;
	.loading_btn_content {
		display: flex;
		align-items: center;
		.time {
			color: #fff;
			margin-left: 0.4rem;
		}
	}
}
::v-deep .checkbox {
	margin: 0.5rem 0.3rem;
	align-items: flex-start;
	.van-checkbox__icon {
		position: relative;
		top: 0.06rem;
		border-radius: 0.06rem;
		.van-icon {
			border-radius: 0.06rem;
		}
		padding-right: 0.2rem;
	}
	.van-checkbox__label {
		font-size: 0.24rem;
		line-height: 0.36rem;
		color: $font_color_thd;
		margin-left: 0;
	}
}
.is_link {
	color: $color_main;
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
</style>

<template>
	<div class="clause">
		<van-checkbox class="checkbox" v-model="rule_1" shape="square" icon-size="0.28rem" label-disabled>
			<span @click="rule_1 = !rule_1">本人已充分理解并同意</span>
			<span class="is_link" @click="seeRules">《保险条款》</span>
			<span class="is_link" @click="seeRule('投保声明')">《投保声明》</span>
			<br />
			<span class="is_link per_rule" @click="seeRule('个人信息保护政策')">《个人信息保护政策》</span>
		</van-checkbox>
		<van-checkbox class="checkbox" v-model="rule_2" shape="square" icon-size="0.28rem" @click="rule_2_check">
			本人已充分理解并同意
			<span class="is_link">《免责内容》</span>
			<span class="is_link">《被保险人同意声明》</span>
			<br />
			<span class="is_link per_rule">《投保提示》</span>
		</van-checkbox>

		<bottom-info-pop v-model="infoPopShow" :title="infoPopTitle" :content="infoPopContent"></bottom-info-pop>

		<van-action-sheet v-model="mustReadPopShow" class="must_read_pop" title="请确认以下内容">
			<div class="must_read_content">
				<div class="tabs">
					<div class="tab" :class="{ active: mustReadIndex === 1 }">
						<span class="text">《免责内容》</span>
						<span class="icon"></span>
					</div>
					<div class="tab" :class="{ active: mustReadIndex === 2 }">
						<span class="text">《被保险人同意声明》</span>
						<span class="icon"></span>
					</div>
					<div class="tab" :class="{ active: mustReadIndex === 3 }">
						<span class="text">《投保提示》</span>
						<span class="icon"></span>
					</div>
				</div>
				<div class="content">
					<div class="content_info" v-show="mustReadIndex === 1" v-html="information.preventDuty">免责内容</div>
					<div class="content_info" v-show="mustReadIndex === 2" v-html="information.insuredDeclare">被保险人同意声明</div>
					<div class="content_info" v-show="mustReadIndex === 3" v-html="information.insuranceTip">投保提示</div>
				</div>
				<div class="loading_btn_box">
					<van-button class="loading_btn" @click="nextDoc" :disabled="isReading" type="info" color="#2594EF" block="" size="normal">
						<div class="loading_btn_content">
							<span v-show="mustReadIndex === 1">已阅读并同意《免责内容》</span>
							<span v-show="mustReadIndex === 2">已阅读并同意《被保险人同意声明》</span>
							<span v-show="mustReadIndex === 3">已阅读并同意《投保提示》</span>
							<van-count-down v-show="isReading" ref="countDown" class="time" :time="200" @finish="finish">
								<template #default="timeData">
									<span class="block">{{ timeData.seconds }}秒</span>
								</template>
							</van-count-down>
						</div>
					</van-button>
				</div>
			</div>
		</van-action-sheet>
	</div>
</template>

<script>
import { Checkbox, ActionSheet, Button, CountDown, Toast } from 'vant';
import { productDetail } from '@/request/api';
import { fixedInfo } from '@/request/api';
export default {
	name: 'clause', // 条款
	components: {
		[Checkbox.name]: Checkbox,
		[ActionSheet.name]: ActionSheet,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		[Toast.name]: Toast,
	},
	model: {
		prop: 'check',
		event: 'change',
	},
	props: {
		productId: {
			type: [String, Number],
			required: true,
		},

		check: {
			type: [Boolean],
			required: true,
		},
	},
	data() {
		return {
			information: {},
			// 条款
			rule_1: false,
			rule_2: false,
			// 投保声明、个人信息保护政策内容
			rule_1_list: [],
			infoPopShow: false,
			infoPopTitle: '',
			infoPopContent: '',
			mustReadPopShow: false,
			mustReadIndex: 1,
			isReading: true,
		};
	},
	watch: {
		rule_1(val) {
			this.$emit('change', val && this.rule_1);
		},
		rule_2(val) {
			this.$emit('change', val && this.rule_2);
		},
	},
	created() {
		this.getProductDetail(this.productId);
		this.getRules()
	},
	methods: {
		// 获取投保声明、个人信息保护政策
		getRules() {
			fixedInfo().then(res => {
				this.rule_1_list = res.fixedInfo;
			});
		},
		// 获取产品信息中的条款
		getProductDetail(productId) {
			productDetail(productId)
				.then(res => {
					this.information = res.information;

				})
				.catch(err => {
					if (err.message === '用户未登录') {
						this.$router.push({
							path: 'login',
							query: {
								pid: this.pid,
							},
						});
					}
				});
		},

		// 去保障条款页面
		seeRules() {
			this.$router.push({
				name: 'safeguardClause',
				params: {
					productId: this.productId,
				},
			});
		},

		seeRule(title) {
			this.infoPopTitle = title;
			this.rule_1_list.forEach(dict => {
				if (dict.typeDesc === title) {
					this.infoPopContent = dict.fixedInfo;
				}
			});
			this.infoPopShow = true;
		},

		nextDoc() {
			if (this.mustReadIndex < 3) {
				this.mustReadIndex++;
				this.isReading = true;
				this.$refs.countDown.reset();
			} else {
				this.rule_2 = true;
				this.mustReadPopShow = false;
			}
		},

		rule_2_check() {
			if (this.rule_2) {
				this.rule_2 = false;
				this.mustReadIndex = 1;
				this.isReading = true;
				this.mustReadPopShow = true;
				this.$nextTick(() => {
					this.$refs.countDown.reset();
				});
			}
		},

		finish() {
			this.isReading = false;
		},
	},
};
</script>
