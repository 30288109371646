<style lang="scss" scoped>
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
::v-deep .protect_user_title .text {
	display: block;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	.user_same {
		padding: 0 0.2rem;
		font-size: 0.24rem;
		line-height: 0.5rem;
		text-align: center;
		border-radius: 0.08rem;
		border: 1px solid $color_main;
		color: #fff;
		background-color: $color_main;
	}
	.btns {
		display: flex;
		justify-content: space-between;
		.name_paste {
			margin-right: 0.2rem;
		}
	}
}
</style>

<template>
	<div class="assured_form">
		<active-title class="protect_user_title">
			<span v-text="`被保人信息`"></span>
			<div class="btns" v-if="sameInsure || sameInsure === ''">
				<div class="user_same name_paste" v-if="false" @click="openNamePasteDialog">名单粘贴</div>
				<div class="user_same" @click="userSame">同投保人</div>
			</div>
		</active-title>
		<van-form ref="form" input-align="right" error-message-align="right" :scroll-to-error="true">
			<van-cell-group :border="false">
				<template v-if="isCompany || isCompany === ''">
					<van-field label="名称" v-model="assuredInfo.name" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="被保公司名称" :rules="rules.name" />
					<van-field label="证件号码" v-model="assuredInfo.cardNo" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="统一社会信用代码" :rules="rules.creditCode" />
				</template>
				<template v-else>
					<van-field label="名称" v-model="assuredInfo.name" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="被保人或被保公司名称" :rules="rules.name" />
					<van-field label="证件号码" v-model="assuredInfo.cardNo" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="统一社会信用代码(或身份证号码)" :rules="rules.cardNum" />
				</template>
				<van-field label="联系地址" v-model="assuredInfo.address" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="联系地址" :rules="rules.address" />
			</van-cell-group>
		</van-form>
	</div>
</template>

<script>
import { CellGroup, Field, Form, Toast } from 'vant';
import Mtils from 'mtils';
import bus from '@/components/bus';
import { cardTypeMap2 } from '@/config/fixedParams';

export default {
	name: 'assuredForm', // 被保险人
	components: {
		[CellGroup.name]: CellGroup,
		[Field.name]: Field,
		[Form.name]: Form,
		[Toast.name]: Toast,
	},
	props: {
		// 是否显示“同投保人”按钮
		sameInsure: {
			type: [Boolean, String],
			required: false,
		},

		// 企业模式
		isCompany: {
			type: [Boolean, String],
			required: false,
			default: false,
		},
	},
	data() {
		return {
			// 被保险人
			assuredInfo: {
				name: '',
				cardNo: '',
				address: '',
			},

			rules: {
				name: [{ required: true }, { pattern: /^[a-zA-Z()（）·]+$|^[\u4e00-\u9fa5()（）·]+$/, message: '只能输入中文或英文以及()·' }, { pattern: /[a-zA-Z]{4,}|[\u4e00-\u9fa5]{2,}/, message: '至少2个中文或4个英文字母' }, { validator: this.nameCheck, message: '·不能出现在首尾' }],
				cardNum: [{ required: true }, { validator: this.cardNumCheck, message: '证件号码有误' }],
				address: [{ required: true }],
				creditCode: [{ required: true }, { validator: this.creditCode, message: '证件号码有误' }],
			},
		};
	},
	created() {
		this.initBus();
	},
	beforeDestroy() {
		this.clearBus();
	},
	methods: {
		getData() {
			return new Promise(resolve => {
				this.$refs.form
					.validate()
					.then(() => {
						let data = Object.assign({}, this.assuredInfo);
						if (Mtils.validation.isIdCard(data.cardNo)) {
							// 身份证
							data.cardTypeCode = cardTypeMap2['身份证'];
							let idInfo = this.$base.getInfoFromIdNumber(data.cardNo);
							data.birthday = idInfo.birthday.replace(/\//g, '-');
							data.sex = idInfo.sex;
						} else {
							data.cardTypeCode = cardTypeMap2['统一社会信用代码'];
						}
						resolve(data);
					})
					.catch(() => {
						Toast('请检查被保人信息');
					});
			});
		},

		// 初始化bus挂载方法
		initBus() {
			bus.$on('receiveInsureData', this.receiveInsureData);
		},

		// 销毁bus方法
		clearBus() {
			bus.$off('receiveInsureData');
		},

		// 被保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},

		// 证件号码校验方法
		cardNumCheck(val) {
			return Mtils.validation.isIdCard(val) || Mtils.validation.isCreditCode(val);
		},

		// 社会统一信用代码校验
		creditCode(val) {
			return Mtils.validation.isCreditCode(val);
		},

		// 接收投保人信息
		receiveInsureData(dict) {
			this.assuredInfo.name = dict.userName;
			this.assuredInfo.cardNo = dict.cardNum;
			this.assuredInfo.address = dict.address;
		},

		// 同投保人
		userSame() {
			bus.$emit('getInsureData');
		},
	},
};
</script>
