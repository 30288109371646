<style lang="scss" scoped>
.fixed_insure {
	margin-left: 20px;
}
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
</style>

<template>
	<div class="insure_form">
		<active-title>
			<slot name="title">投保人信息</slot>
			<template v-if="fixed || fixed === ''" #custom>
				<van-checkbox class="fixed_insure" icon-size="16px" v-model="isFixedInsure" shape="square">固定投保人</van-checkbox>
			</template>
		</active-title>
		<van-form ref="form" input-align="right" error-message-align="right" :scroll-to-error="true">
			<van-cell-group :border="false">
				<template v-if="isCompany || isCompany === ''">
					<van-field label="企业名称" v-model="insureInfo.userName" :formatter="nameFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写企业名称" :rules="rules.userName" />
					<van-field label="证件号码" v-model="insureInfo.cardNum" :formatter="idCardNoFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="社会统一信用代码" :rules="rules.creditCode" />
				</template>
				<template v-else>
					<van-field label="投保人类型" :value="insureInfo.userType" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" class="no_link" placeholder="请选择投保人类型" />
					<van-field label="投保人名称" v-model="insureInfo.userName" :formatter="nameFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="姓名或企业名称" :rules="rules.userName" />
					<van-field label="证件号码" v-model="insureInfo.cardNum" :formatter="idCardNoFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="身份证或社会统一信用代码" :rules="rules.cardNum" />
				</template>
				<van-field label="手机号码" v-model="insureInfo.phone" :formatter="mobileFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写投保人手机号码" :rules="rules.phone" />
				<van-field label="联系地址" v-model="insureInfo.address" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写投保联系地址" :rules="rules.address" />
				<van-field label="邮箱" v-model="insureInfo.email" :formatter="emailFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="接收电子保单邮件" />
			</van-cell-group>
		</van-form>
	</div>
</template>

<script>
import { Cell, CellGroup, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, ActionSheet, Button, CountDown, Form, Toast, Dialog } from 'vant';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';
import bus from '@/components/bus';
import { insureUserTypeMap, cardTypeMap2 } from '@/config/fixedParams';
import { http_getLastInsured } from '@/request/sundry';

export default {
	name: 'insureForm', // 投保信息
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Checkbox.name]: Checkbox,
		[ActionSheet.name]: ActionSheet,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		[Form.name]: Form,
		[Toast.name]: Toast,
		[Dialog.Component.name]: Dialog.Component,
	},
	props: {
		// 是否显示“固定投保人”
		fixed: {
			type: [Boolean, String],
			required: false,
			default: false,
		},

		// 是否自动填充投保人,暂时只能有一个页面拥有此功能，因为投保人信息可能不同，不同的投保人信息不能完美的填充到表单中
		fill: {
			type: Boolean,
			required: false,
			default: false,
		},

		// 企业模式
		isCompany: {
			type: [Boolean, String],
			required: false,
			default: false,
		},
	},
	data() {
		return {
			isFixedInsure: false,

			// 投保人信息
			insureInfo: {
				userType: '个人或企业',
				userName: '',
				cardNum: '',
				phone: '',
				address: '',
				email: '',
			},

			rules: {
				userName: [{ required: true }, { pattern: /^[a-zA-Z()（）·]+$|^[\u4e00-\u9fa5()（）·]+$/, message: '只能输入中文或英文以及()·' }, { pattern: /[a-zA-Z]{4,}|[\u4e00-\u9fa5]{2,}/, message: '至少2个中文或4个英文字母' }, { validator: this.nameCheck, message: '·不能出现在首尾' }],
				cardNum: [{ required: true }, { validator: this.cardNumCheck, message: '证件号码有误' }],
				phone: [{ required: true }, { validator: this.phoneCheck, message: '请填写正确的手机号码' }],
				email: [{ required: false }, { validator: this.emailCheck, message: '请输入正确的邮箱地址' }],
				creditCode: [{ required: true }, { validator: this.creditCode, message: '证件号码有误' }],
			},
		};
	},
	created() {
		this.fillForm();
		this.initBus();
	},
	beforeDestroy() {
		this.clearBus();
	},
	methods: {
		getData() {
			return new Promise(resolve => {
				this.$refs.form
					.validate()
					.then(() => {
						let data = Object.assign({ isFixedInsure: this.isFixedInsure }, this.insureInfo);
						// 在这里要做数据处理，个人的时候，解析出出生日期、性别等
						if (Mtils.validation.isIdCard(data.cardNum)) {
							let idInfo = this.$base.getInfoFromIdNumber(data.cardNum);
							Object.assign(data, idInfo, {
								insType: insureUserTypeMap['个人'],
								cardType: cardTypeMap2['身份证'],
							});
							data.birthday = data.birthday.replace(/\//g, '-');
						} else {
							Object.assign(data, {
								insType: insureUserTypeMap['企业'],
								cardType: cardTypeMap2['统一社会信用代码'],
							});
						}
						resolve(data);
					})
					.catch(() => {
						Toast('请检查投人保信息');
					});
			});
		},

		// 自动填充投保人
		fillForm() {
			if (!this.fill) {
				return;
			}
			http_getLastInsured().then(res => {
				console.log(res);
				if (res.insName) this.$set(this.insureInfo, 'userName', res.insName);
				if (res.insCredentials) this.$set(this.insureInfo, 'cardNum', res.insCredentials);
				if (res.insPhone) this.$set(this.insureInfo, 'phone', res.insPhone);
				if (res.insAddress) this.$set(this.insureInfo, 'address', res.insAddress);
				if (res.insEmail) this.$set(this.insureInfo, 'email', res.insEmail);
			});
		},

		initBus() {
			bus.$on('getInsureData', () => {
				this.getData().then(res => {
					bus.$emit('receiveInsureData', res);
				});
			});
		},

		// 销毁bus方法
		clearBus() {
			bus.$off('getInsureData');
		},

		// 投保人姓名格式化
		nameFormatter(v) {
			return v.toLocaleUpperCase().replace(/ /g, '');
		},

		// 身份证号码格式化
		idCardNoFormatter(v) {
			return String(v).toLocaleUpperCase().replace(/ /g, '');
		},

		// 手机号码格式化
		mobileFormatter(v) {
			return String(v).replace(/ /g, '');
		},

		// 邮箱格式化
		emailFormatter(v) {
			return String(v).replace(/ /g, '');
		},

		// 投保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},

		// 证件号码校验方法
		cardNumCheck(val) {
			return Mtils.validation.isIdCard(val) || Mtils.validation.isCreditCode(val);
		},

		// 社会统一信用代码校验
		creditCode(val) {
			return Mtils.validation.isCreditCode(val);
		},

		// 手机号码校验
		phoneCheck(val) {
			return regular.phone.test(val);
		},

		// 邮箱校验
		emailCheck(val) {
			if (val) {
				return regular.email.test(val);
			}
			return true;
		},
	},
};
</script>
