<style lang="scss" scoped>
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
</style>

<template>
	<div class="protect_limit">
		<van-form ref="form" input-align="right" error-message-align="right" :scroll-to-error="true">
			<active-title>
				<slot name="title">保障期限</slot>
			</active-title>
			<van-cell-group :border="false">
				<van-field label="保障期限" :value="proteceInfo.time" v-if="plan || plan === ''" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="picker" placeholder="请选择保障期限" @click="timePop = true" :rules="rules.time" />
				<van-field label="保险起期" :value="proteceInfo.start" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="calendar" placeholder="请选择起保日期" @click="openStart" :rules="rules.start" />
				<van-field label="保险止期" :value="proteceInfo.end" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="calendar" placeholder="请选择终保日期" @click="openEnd" :rules="rules.end" />
			</van-cell-group>
		</van-form>

		<!-- 起保日期 -->
		<van-calendar v-model="startDatePop" @confirm="startDateCheck" :min-date="startDateMinDate" color="#2594EF" :formatter="$base.calendarFormatter" />

		<!-- 终保日期 -->
		<van-calendar v-model="endDatePop" @confirm="endDateCheck" :default-date="new Date(proteceInfo.end)" :min-date="endDateMinDate" :max-date="endDateMaxDate" color="#2594EF" :formatter="$base.calendarFormatter" />

		<!-- 起保日期时间 -->
		<van-action-sheet v-model="startDateHourPop">
			<van-datetime-picker v-model="startDateHour" type="datehour" title="保险起期" @confirm="startDateTimeCheck" @cancel="startDateHourPop = false" :min-date="startMinDateHour" :formatter="dateTimeFormatter" />
		</van-action-sheet>

		<!-- 终保日期时间 -->
		<van-action-sheet v-model="endDateHourPop">
			<van-datetime-picker v-model="endDateHour" type="datehour" title="保险止期" @confirm="endDateTimeCheck" @cancel="endDateHourPop = false" :min-date="endMinDateHour" :max-date="endMaxDateHour" :formatter="dateTimeFormatter" :filter="dateHourFilter" />
		</van-action-sheet>

		<!-- 保障期限 -->
		<van-popup v-model="timePop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="保障期限" show-toolbar value-key="view_time" :visible-item-count="3" :columns="timeList" @confirm="timeCheck" @cancel="timePop = false" />
		</van-popup>
	</div>
</template>

<script>
import { CellGroup, Popup, Picker, Calendar, Field, Button, Form, Toast, DatetimePicker, ActionSheet } from 'vant';
import { productDictInfo, getInsuerInfo } from '@/request/api';
import moment from 'moment';

export default {
	name: 'protectLimit', // 保障期限
	components: {
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[Button.name]: Button,
		[Form.name]: Form,
		[DatetimePicker.name]: DatetimePicker,
		[ActionSheet.name]: ActionSheet,
		[Toast.name]: Toast,
	},
	props: {
		// 产品id
		productId: {
			type: [Number, String],
			required: true,
		},

		// 计划id
		planId: {
			type: [Number, String],
			required: true,
		},

		// 价格id
		priceId: {
			type: [Number, String],
			required: false,
			default: null,
		},

		plan: {
			type: [String, Boolean],
			required: false,
			default: false,
		},

		time: {
			type: [Boolean, String],
			required: false,
			default: false,
		},

		// 是否默认填充结束日期
		fillEndDate: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data() {
		return {
			product: {},
			proteceInfo: {
				time: '',
				start: '',
				end: '',
			},

			rules: {
				time: [{ required: true }],
				start: [{ required: true }],
			},

			// 投保信息日历弹出层
			startDatePop: false,
			endDatePop: false,
			endDateMinDate: new Date(),
			endDateMaxDate: new Date(),

			// 投保信息时间弹出层
			startDateHourPop: false, // 弹层显示
			endDateHourPop: false, // 弹层显示
			startMinDateHour: new Date(), // 起保时间最小时间
			endMinDateHour: new Date(), // 终保时间最小时间
			endMaxDateHour: new Date(), // 终保时间最大时间
			endDateHourPickerHour: '00', // 终保时间固定的小时
			startDateHour: '', // 起保时间
			endDateHour: '', // 终保时间
			formatterMap: {
				year: '年',
				month: '月',
				day: '日',
				hour: '时',
			},

			// 保障期限
			timeLimitDict: null,
			timePop: false,
			timeList: [],

			startDateMinDate: new Date(),
			startSecond: '00:00:00',
			isNextSecondStart: false, // 是否即时起保
		};
	},
	watch: {
		proteceInfo: {
			handler: function (v) {
				if (v.start) {
					this.$emit('change', Object.assign({}, v, this.timeLimitDict));
				}
			},
			deep: true,
		},
	},
	created() {
		this.init();
	},
	methods: {
		getData() {
			return new Promise(resolve => {
				this.$refs.form
					.validate()
					.then(() => {
						let data = {};
						// 日期格式化
						if (this.time) {
							data.start = this.$base.getDateTime(this.proteceInfo.start);
							data.end = this.$base.getDateTime(this.proteceInfo.end);
						} else {
							data.start = this.$base.submitDateFormatter(this.proteceInfo.start, 1);
							data.end = this.$base.submitDateFormatter(this.proteceInfo.end, 2);
						}
						data.time = this.proteceInfo.time;
						resolve(data);
					})
					.catch(() => {
						Toast('请检查投保信息');
					});
			});
		},

		init() {
			this.getProduct(this.productId, this.planId).then(() => {
				this.setStartDate();
			});
			this.getProductInfo(this.productId, this.planId);
		},

		// 打开起保日期选择层
		openStart() {
			if (this.time) {
				this.startDateHourPop = true;
			} else {
				this.startDatePop = true;
			}
		},

		// 打开终保日期选择层
		openEnd() {
			if (this.time) {
				this.endDateHourPop = true;
			} else {
				this.endDatePop = true;
			}
		},

		dateTimeFormatter(type, val) {
			return `${val}${this.formatterMap[type]}`;
		},

		dateHourFilter(type, vals) {
			if (type === 'hour') {
				return [this.endDateHourPickerHour];
			}
			return vals;
		},

		getProduct(productId, planId) {
			return new Promise(resolve => {
				let send = {
					comboId: planId,
					backInsureFlag: '',
				};
				getInsuerInfo(productId, send)
					.then(res => {
						this.product = res.product;
						this.$store.commit('set_sundryFormInput', res.formInput);
						resolve();
					})
					.catch(err => {
						if (err.message === '用户未登录') {
							this.$router.push({
								path: 'login',
								query: {
									pid: this.pid,
								},
							});
						}
					});
			});
		},

		setStartDate() {
			if (this.product.immediatelyFlag && this.product.immediatelyTimeLimit > new Date().getHours()) {
				//即时投保
				this.startDateMinDate = new Date();
				this.startMinDateHour = new Date(Date.now() + 2 * 60 * 60 * 1000);
			} else {
				this.startDateMinDate = new Date(Date.now() + 8.64e7);
				this.startMinDateHour = new Date(Date.now() + 8.64e7);
			}
			this.endDateHourPickerHour = String(new Date().getHours()).padStart(2, '0');
		},

		// 获取产品信息
		getProductInfo(productId, planId) {
			productDictInfo(productId, planId)
				.then(res => {
					if (this.priceId !== null) {
						this.timeList = res.insureTimeList.filter(item => String(item.price_id) == String(this.priceId));
					} else {
						let hasviewtime = [];
						this.timeList = [];
						res.insureTimeList.forEach(item => {
							if (!hasviewtime.includes(item.view_time)) {
								this.timeList.push(item);
								hasviewtime.push(item.view_time);
							}
						});
					}
					if (this.timeList.length) {
						this.timeCheck(this.timeList[0]);
					}
				})
				.catch(err => {
					if (err.message === '用户未登录') {
						this.$router.push({
							path: 'login',
							query: {
								pid: this.pid,
							},
						});
					}
				});
		},

		// 选择保障期限
		timeCheck(val) {
			console.log(val);
			this.timeLimitDict = val;
			this.proteceInfo.time = val.view_time;
			this.$store.commit('set_sundryViewTime', val.view_time);
			this.timePop = false;
			if (this.time) {
				this.setEndHour();
			} else {
				this.setEndDate();
			}
		},

		// 日期模式-计算终保日期
		setEndDate() {
			if (this.proteceInfo.time && this.proteceInfo.start) {
				let startDate = new Date(this.proteceInfo.start);
				let timeDict = this.timeLimitDict;
				// insureTimeUnit 1-天;2-月;3-年;4-终身;5-至被保人?周岁
				// 此处为旅意险，只处理天、月、年的情况
				let momentAddUnit = 'd';
				switch (timeDict.insure_time_unit) {
					case 1:
						// 天
						momentAddUnit = 'd';
						break;

					case 2:
						// 月
						momentAddUnit = 'M';
						break;

					case 3:
						// 年
						momentAddUnit = 'y';
						break;

					default:
						break;
				}

				let endTime = moment(this.proteceInfo.start).add(Number(timeDict.insure_time), momentAddUnit).subtract(1, 'd').format('YYYY/MM/DD');
				if (this.fillEndDate) {
					this.proteceInfo.end = endTime;
				} else {
					this.proteceInfo.end = '';
				}

				this.endDateMaxDate = new Date(endTime);

				if (timeDict.insure_time_unit == 1) {
					// 如果单位为“天”，终保时间可以自由选择
					this.endDateMinDate = startDate;
				} else {
					if (timeDict.view_time.includes('-') && !timeDict.view_time.includes('（')) {
						let minDaysStr = timeDict.view_time.split('-')[0];
						let minEndMs = startDate.getTime() + (Number(minDaysStr) - 1) * 24 * 60 * 60 * 1000;
						this.endDateMinDate = new Date(minEndMs);
					} else {
						this.endDateMinDate = new Date(endTime);
					}
				}
			}
		},

		// 日期模式-选择起保时间
		startDateCheck(val) {
			if (this.product.immediatelyFlag && this.product.immediatelyTimeLimit > new Date().getHours()) {
				//即时投保
				let date = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
				this.startSecond = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':' + (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
				this.isNextSecondStart = true;
			} else {
				this.startSecond = '00:00:00';
				this.isNextSecondStart = false;
			}

			this.proteceInfo.start = this.$base.dateFormater(val);
			this.startDatePop = false;
			this.setEndDate();
		},

		endDateCheck(val) {
			this.proteceInfo.end = this.$base.dateFormater(val);
			this.endDatePop = false;
		},

		// 日期小时模式-选择起保时间
		startDateTimeCheck(val) {
			let date = this.$base.dateFormater(val);
			this.proteceInfo.start = `${date} ${val.getHours()}:00:00`;
			this.startDateHourPop = false;
			this.setEndHour();
		},

		// 日期小时模式-选择终保时间
		endDateTimeCheck(val) {
			let date = this.$base.dateFormater(val);
			this.proteceInfo.end = `${date} ${val.getHours()}:59:59`;
			this.endDateHourPop = false;
			// 检查实际的保障期限
			this.checkProtectLimit();
		},

		// 日期小时模式-计算终保时间
		// 此处有隐患，既然是精确到小时了，那应该不会涉及到整月、整年的计算，就暂时按照天数计算
		setEndHour() {
			if (this.proteceInfo.time && this.proteceInfo.start) {
				let startDate = new Date(this.proteceInfo.start);
				let timeDict = this.timeLimitDict;
				let endMs = startDate.getTime() + Number(timeDict.totalDays) * 24 * 60 * 60 * 1000 - 1;
				let endMsObject = new Date(endMs);
				let hour = endMsObject.getHours();
				this.proteceInfo.end = `${this.$base.dateFormater(endMsObject)} ${this.$base.getTime(endMsObject)}`;
				this.endDateHour = new Date(`${this.$base.dateFormater(endMsObject)} ${hour}:00:00`);

				// 接下来要给终保日期的选项做过滤处理
				this.endMinDateHour = new Date(startDate.getTime() + 1 * 24 * 60 * 60 * 1000 - 1);
				this.endMaxDateHour = endMsObject;
				this.endDateHourPickerHour = String(hour).padStart(2, '0');
			}
		},

		// 检查保障期限,如果保障期限有更合适的，给用户选成更合适的保障期限
		checkProtectLimit() {
			if (this.proteceInfo.start) {
				let limit = Math.ceil((new Date(this.proteceInfo.end) - new Date(this.proteceInfo.start)) / 1000 / 3600 / 24);
				if (limit !== this.timeLimitDict.totalDays) {
					let timeList = JSON.parse(JSON.stringify(this.timeList));
					let limits = timeList.sort((a, b) => {
						return a.totalDays - b.totalDays;
					});
					for (let i = 0; i < limits.length; i++) {
						const item = limits[i];
						if (limit - item.totalDays <= 0) {
							if (item.price_id !== this.timeLimitDict.price_id) {
								// 改变保障期限的选择
								this.timeCheck(item);
							}
							break;
						}
					}
				}
			}
		},
	},
};
</script>
