<style lang="scss" scoped>
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
</style>

<template>
	<div class="link_man">
		<active-title class="protect_user_title">被保人联系信息</active-title>

		<van-form ref="form" input-align="right" error-message-align="right" :scroll-to-error="true">
			<van-cell-group :border="false">
				<van-field label="姓名" v-model="form.name" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="联系人姓名" :rules="rules.name" />
				<van-field label="手机号码" v-model="form.phone" :formatter="mobileFormatter" class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link placeholder="请填写联系人手机号码" :rules="rules.phone" />
			</van-cell-group>
		</van-form>
	</div>
</template>

<script>
import { CellGroup, Field, Form, Toast } from 'vant';
import regular from '@/assets/js/regular';

export default {
	name: 'linkMan', // 联系人
	components: {
		[CellGroup.name]: CellGroup,
		[Field.name]: Field,
		[Form.name]: Form,
		[Toast.name]: Toast,
	},
	data() {
		return {
			form: {
				name: '',
				phone: '',
			},

			rules: {
				name: [{ required: true }, { pattern: /^[a-zA-Z()（）·]+$|^[\u4e00-\u9fa5()（）·]+$/, message: '只能输入中文或英文以及()·' }, { pattern: /[a-zA-Z]{4,}|[\u4e00-\u9fa5]{2,}/, message: '至少2个中文或4个英文字母' }, { validator: this.nameCheck, message: '·不能出现在首尾' }],
				phone: [{ required: true }, { validator: this.phoneCheck, message: '请填写正确的手机号码' }],
			},
		};
	},
	created() {},
	methods: {
		getData() {
			return new Promise(resolve => {
				this.$refs.form
					.validate()
					.then(() => {
						resolve({
							linkName: this.form.name,
							linkMobile: this.form.phone,
						});
						resolve(this.form);
					})
					.catch(() => {
						Toast('请检查被保人联系信息');
					});
			});
		},

		// 被保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},

		phoneCheck(val) {
			if (!val) {
				return true;
			}
			return regular.phone.test(val);
		},

		// 手机号码格式化
		mobileFormatter(v) {
			return String(v).replace(/ /g, '');
		},
	},
};
</script>
